<template>
  <div>
    <ManageFileDownload />
  </div>
</template>
<script>
import ManageFileDownload from "@/components/ManageFileDownload/ManageFileDownload";
export default {
  components: {
    ManageFileDownload,
  },
  created() {},
};
</script>
