<template>
  <v-form>
    <loading :active.sync="loading"></loading>
    <v-row align="center" justify="center">
      <v-col cols="12" md="6" sm="6">
        <span
          style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          "
        >
          File Download
        </span>
      </v-col>
      <v-col cols="12" md="6" sm="6">
        <v-row>
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <v-btn
              @click="goToCreate()"
              color="#2AB3A3"
              dark
              style="height: 48px"
            >
              + เพิ่ม File Download
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-card class="pa-3" width="100%" color="#F5F5F5" flat>
        <v-card class="pa-3" width="100%">
          <v-col cols="12">
            <v-data-table
              :headers="headersBrand"
              :items="items1"
              :search="search"
              disable-pagination
              hide-default-footer
              class="elevation-3"
            >
              <template v-slot:[`item.download`]="{ item }">
                <v-icon @click="DownloadDocument(item.download)"
                  >mdi-download</v-icon
                >
              </template>
              <template v-slot:[`item.img_url`]="{ item }">
                <v-img :src="item.img_url" width="120px" class="ma-1"></v-img>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-icon @click="ViewNews(item)">mdi-content-copy</v-icon>
                <v-icon @click="UpdateNews(item)">mdi-pencil</v-icon>
                <v-icon @click="DeleteItem(item)">mdi-delete</v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-card>
      </v-card>
    </v-row>
  </v-form>
</template>
<script>
import draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
import moment from "moment";
export default {
  components: {
    draggable,
    Loading,
  },
  data() {
    return {
      tab: null,
      typeCheck: 0,

      headersBrand: [
        { text: "ลำดับ", value: "sortindex", align: "start" },
        { text: "ชื่อหัวข้อ (ไทย)", value: "title", align: "start" },
        { text: "สถานะ", value: "status", align: "center" },
        { text: "Download", value: "download", align: "start" },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],
      dialog: false,
      isEdit: false,
      items1: [],
      items2: [],
      search: "",
      sortindex: 0,
      user: "",
      loading: false,
      imgUrl: "",
      e1: 0,
    };
  },
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {
    this.user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
    this.getAll();
    // this.getAll1();
    document.title = "File Download - GSCM Management System";
  },
  methods: {
    DownloadDocument(val) {
      console.log("val", val);
      window.open(val);
    },
    convertDate(val) {
      return moment(val).format("MM/DD/YYYY hh:mm");
    },
    async DragItems(val) {
      this.loading = true;
      console.log("this.items1", this.items1);
      console.log("beforeAPI", val);
      var user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      // const response = await this.axios.put(
      //   `${process.env.VUE_APP_API}/knowledges/updateIndex?news_type=ข่าวประชาสัมพันธ์`,
      //   val,
      //   auth
      // );
      console.log("afterAPI", response);

      this.items = response.data.data;
      // for (let i in this.items) {
      //   this.items[i].count = parseInt(i) + 1;
      // }
      this.loading = false;
    },
    async getAll() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/fileDownloadStds`,
        auth
      );
      console.log("getAll", response.data.data);
      this.items1 = response.data.data;
      this.imgUrl = response.data.data.imgUrl;
      for (let i in this.items1) {
        this.items1[i].sortindex = parseInt(i) + 1;
      }
      this.loading = false;
    },

    async DeleteItem(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/fileDownloadStds/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAll();
          // this.getAll1();
        }
      });
    },
    goToCreate() {
      this.$router.push("CreateFileDownload");
    },
    UpdateNews(val) {
      localStorage.setItem("FileDownloadData", Encode.encode(val));
      this.$router.push("EditFileDownload");
    },

    ViewNews(val) {
      localStorage.setItem("FileDownloadData", Encode.encode(val));
      this.$router.push("ViewFileDownload");
    },
  },
};
</script>